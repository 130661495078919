import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ShoppingBag,
  Gamepad2,
  Crown,
  Sparkles,
  Check,
  Menu,
  Play,
  ShoppingCart,
  Mail,
  Target,
  TrendingUp,
  Users,
  Star,
  Zap,
  ArrowRight,
} from "lucide-react";
import { auth } from "../../firebase-config";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [rotatingText, setRotatingText] = useState("Social Media Following");
  const rotatingTexts = [
    "Social Media Following",
    "Big Email List",
    "Influencer Network",
    "Contact List",
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % rotatingTexts.length;
      setRotatingText(rotatingTexts[currentIndex]);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const scrollToPricing = () => {
    const pricingSection = document.getElementById("pricing-section");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAuth = (type) => {
    navigate("/Auth", { state: { authType: type } });
  };

  const handleDashboard = () => {
    navigate("/Dashboard");
  };

  const GoldMineText = ({ children }) => (
    <span className="relative inline-block font-extrabold">
      <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-400 opacity-50 blur-sm"></span>
      <span className="relative goldText">{children}</span>
    </span>
  );

  const HighlightText = ({ children }) => (
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 font-bold">
      {children}
    </span>
  );
  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-100 via-red-100 to-pink-100">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center gap-5">
              <span role="img" aria-label="Penguin" className="text-2xl">
                🐧
              </span>
              <span
                style={{
                  fontFamily: '"Press Start 2P", cursive',
                  fontWeight: 400,
                  margin: "0 0 var(--space)",
                  textShadow: "2px 3px 0 orange",
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  fontSize: "20px", // Increased font size for Pikvin text
                }}
              >
                Pikvin
              </span>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <button className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out ">
                Shop
              </button>
              <button className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out">
                Play
              </button>
              <button
                onClick={scrollToPricing}
                className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out"
              >
                Pricing
              </button>
              {user ? (
                <button
                  onClick={handleDashboard}
                  className="bg-gradient-to-r from-red-500 to-pink-500 text-white hover:from-red-600 hover:to-pink-600 px-4 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out"
                >
                  Dashboard
                </button>
              ) : (
                <>
                  <button
                    onClick={() => handleAuth("login")}
                    className="text-red-600 border border-red-600 hover:bg-red-600 hover:text-white px-4 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out"
                  >
                    Login
                  </button>
                  <button
                    onClick={() => handleAuth("signup")}
                    className="bg-gradient-to-r from-red-500 to-pink-500 text-white hover:from-red-600 hover:to-pink-600 px-4 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out"
                  >
                    Sign Up
                  </button>
                </>
              )}
            </div>
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-600 hover:text-red-500"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="#"
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Shop
              </a>
              <a
                href="#"
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Play
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToPricing();
                  setIsMenuOpen(false);
                }}
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Pricing
              </a>
              {user ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDashboard();
                    setIsMenuOpen(false);
                  }}
                  className="bg-gradient-to-r from-red-400 to-pink-400 text-white hover:from-red-500 hover:to-pink-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Dashboard
                </a>
              ) : (
                <>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAuth("login");
                      setIsMenuOpen(false);
                    }}
                    className="text-red-500 border border-red-500 hover:bg-red-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Login
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAuth("signup");
                      setIsMenuOpen(false);
                    }}
                    className="bg-gradient-to-r from-red-400 to-pink-400 text-white hover:from-red-500 hover:to-pink-500 block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Sign Up
                  </a>
                </>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg text-gray-800 py-10 sm:py-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center justify-between">
              <div className="w-full mb-10 text-center">
                <h1
                  style={{
                    fontFamily: '"Press Start 2P", cursive',
                    letterSpacing: "-0.05em",
                  }}
                  className="text-2xl sm:text-3xl lg:text-4xl font-extrabold mb-4 leading-tight w-full py-6 px-4 md:w-full"
                >
                  Hyper Growth Tool For{" "}
                  <span className="text-purple-600">Fashion Brands</span> 🛍
                </h1>
                <p className="text-lg sm:text-xl mb-8 text-gray-600">
                  Fun Way For Your Customers To Refer To Their Friends!
                </p>
                <button className="bg-gradient-to-r from-purple-400 to-pink-400 hover:from-purple-500 hover:to-pink-500 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center mx-auto">
                  <Play size={20} className="mr-2" />
                  Watch Demo
                </button>
              </div>
              <div className="w-full max-w-2xl">
                <div
                  className="relative rounded-lg overflow-hidden shadow-2xl"
                  style={{ paddingBottom: "56.25%" }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/T060R2QiahA"
                    title="Pikvin Explainer Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <TrendingUp className="h-12 w-12 text-red-500" />,
                title: "Immediate Growth",
                description: "Grow Your Customer Base Rapidly",
              },
              {
                icon: <Target className="h-12 w-12 text-pink-500" />,
                title: "Powerful Targeting",
                description:
                  "Target to New Customers On The Product They Liked The Most!",
              },
              {
                icon: <Users className="h-12 w-12 text-yellow-500" />,
                title: "Valuable Customer Engagement",
                description:
                  "New Users Browse Your Product Catalogue to Pick Their Favorite",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white border-none shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6"
              >
                <div className="flex justify-center">{feature.icon}</div>
                <h3 className="text-xl font-bold text-center mt-4">
                  {feature.title}
                </h3>
                <p className="text-center text-gray-600 mt-2">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* How It Works Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            How Pikvin Works
          </h2>
          <div className="flex justify-center items-center space-x-4">
            {[
              {
                icon: <Play className="h-8 w-8 text-green-500" />,
                text: "Play",
                bgColor: "bg-green-200",
              },
              {
                icon: <ShoppingCart className="h-8 w-8 text-blue-500" />,
                text: "Select Favorite Product",
                bgColor: "bg-blue-200",
              },
              {
                icon: <Mail className="h-8 w-8 text-purple-500" />,
                text: "Submit Email",
                bgColor: "bg-purple-200",
              },
              {
                icon: <Sparkles className="h-8 w-8 text-yellow-500" />,
                text: "Get Retargeted",
                bgColor: "bg-yellow-200",
              },
            ].map((item, index) => (
              <React.Fragment key={item.text}>
                <div className="text-center">
                  <div
                    className={`${item.bgColor} rounded-full p-4 inline-block`}
                  >
                    {item.icon}
                  </div>
                  <p className="mt-2">{item.text}</p>
                </div>
                {index < 3 && <div className="text-4xl text-gray-400">→</div>}
              </React.Fragment>
            ))}
          </div>
        </section>
        <section className="mb-16 bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Unlock Your Audience's Potential
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <div className="flex-1 text-center md:text-left">
              <h3 className="text-2xl font-semibold mb-4 text-purple-700">
                <span>Got a </span>
                <span className="relative">
                  <span className="absolute -inset-1 bg-gradient-to-r from-purple-400 to-pink-400 blur opacity-25"></span>
                  <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">
                    {rotatingText}
                  </span>
                </span>
                <span>?</span>
              </h3>
              <p className="text-lg text-gray-700 mb-6">
                You're sitting on a <GoldMineText>Gold Mine</GoldMineText>!
                Pikvin giveaway tool encourages Your current customers and
                followers to invite their friends to your store and play the
                pikvin game which converts them into customers!
              </p>
              <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-6 rounded-full text-lg font-semibold hover:from-purple-600 hover:to-pink-600 transition duration-300 cursor-pointer custom-cursor custom-cursor">
                Start Converting Today
              </button>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="relative">
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25"></div>
                <div className="relative bg-white p-6 rounded-lg shadow-xl">
                  <Star className="h-16 w-16 text-yellow-400 mx-auto mb-4" />
                  <h4 className="text-xl font-bold text-center mb-2">
                    Unleash the Power
                  </h4>
                  <ul className="space-y-2">
                    {[
                      "Convert followers to customers",
                      "Highly Targeted Retargeting",
                      "Customers Browse Your Whole Product Catalogue",
                      "Boost your ROI effortlessly",
                    ].map((item, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing-section" className="mb-16">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
            Choose Your Perfect Plan
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Starter",
                price: "₹970",
                period: "month",
                description: "Perfect for small businesses and startups",
                features: [
                  "Up to 1,000 customers",
                  "Basic analytics",
                  "Phone, Email and Chat support",
                ],
                cta: "Get Started",
                highlight: false,
              },
              {
                name: "Pro",
                price: "₹1970",
                period: "month",
                description: "Ideal for growing businesses",
                features: [
                  "Up to 2,000 customers",
                  "Advanced analytics",
                  "Priority email & chat support",
                  "Custom integrations",
                ],
                cta: "Upgrade to Pro",
                highlight: true,
              },
              {
                name: "Enterprise",
                price: "₹20,000",
                period: "month",
                description: "For large-scale operations",
                features: [
                  "Unlimited customers",
                  "Enterprise-grade analytics",
                  "24/7 phone, email & chat support",
                  "Unlimited team members",
                  "Dedicated account manager",
                  "Custom development",
                ],
                cta: "Contact Sales",
                highlight: false,
              },
            ].map((plan) => (
              <div
                key={plan.name}
                className={`flex flex-col ${
                  plan.highlight
                    ? "bg-gradient-to-br from-red-50 to-pink-50 border-2 border-red-500"
                    : "bg-white"
                } shadow-xl rounded-2xl overflow-hidden transform transition duration-500 hover:scale-105`}
              >
                <div className="p-8 flex-grow">
                  <h3 className="text-2xl font-bold text-center mb-2">
                    {plan.name}
                  </h3>
                  <div className="text-center mb-6">
                    <span className="text-4xl font-bold">{plan.price}</span>
                    {plan.period && (
                      <span className="text-gray-500">/{plan.period}</span>
                    )}
                  </div>
                  <p className="text-center text-gray-600 mb-6">
                    {plan.description}
                  </p>
                  <ul className="space-y-3 mb-6">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="p-6">
                  <button
                    className={`w-full py-3 px-4 rounded-lg text-lg font-semibold transition-colors duration-300 ${
                      plan.highlight
                        ? "bg-gradient-to-r from-red-500 to-pink-500 text-white hover:from-red-600 hover:to-pink-600"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    }`}
                  >
                    {plan.cta}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Form */}
        <section className="py-12">
          <div className="max-w-lg mx-auto bg-gradient-to-br from-purple-200 to-blue-200 shadow-xl rounded-xl overflow-hidden">
            <div className="p-8">
              <h2 className="text-3xl font-bold text-center text-indigo-900 mb-6">
                Get in Touch
              </h2>
              <form className="space-y-6">
                <input
                  className="w-full px-4 py-3 border-0 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-sm"
                  placeholder="Your Name"
                  type="text"
                  required
                />
                <input
                  className="w-full px-4 py-3 border-0 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-sm"
                  placeholder="Your Email"
                  type="email"
                  required
                />
                <textarea
                  className="w-full px-4 py-3 border-0 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-sm"
                  placeholder="Your Message"
                  rows="5"
                  required
                ></textarea>
                <button
                  type="submit"
                  className="w-full py-3 px-4 rounded-lg text-lg font-semibold bg-gradient-to-r from-indigo-500 to-purple-500 text-white hover:from-indigo-600 hover:to-purple-600 transition-colors duration-300"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-red-300 to-red-500 text-white py-10 mt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <span role="img" aria-label="Penguin" className="text-3xl">
                🐧
              </span>
              <span className="text-2xl font-bold">Pikvin</span>
            </div>
            <div className="text-center text-sm">
              © 2024 VEGO TECHNOLOGIES. All rights reserved.
            </div>
            <div className="flex space-x-6">
              {["About", "Blog", "Support", "Terms", "Privacy"].map((item) => (
                <a
                  key={item}
                  href="#"
                  className="hover:text-white hover:underline"
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;

import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Auth from "./Pages/Auth/Auth";
import Overview from "./Components/Overview/Overview";
import Analytics from "./Components/Analytics/Analytics";
import Customers from "./Components/Customers/Customers";
import Coupons from "./Components/Coupons/Coupons";
import Templates from "./Components/Templates/Templates";
import Settings from "./Components/Settings/Settings";
import Contact from "./Components/Contact/Contact";
import { auth } from "./firebase-config";
import ProductTable from "./Components/ProductTable/ProductTable";
import { BrandProvider } from "./BrandContext";
import Campaigns from "./Components/Campaigns/Campaigns";
import HomeAlt from "./Pages/Home/HomeAlt";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);
  return (
    <BrandProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/auth"
              element={user ? <Navigate to="/dashboard" replace /> : <Auth />}
            />
            <Route
              path="/dashboard/*"
              element={
                user ? (
                  <Dashboard>
                    <Routes>
                      <Route path="/" element={<Overview />} />
                      <Route path="/overview" element={<Overview />} />
                      <Route path="/analytics" element={<Analytics />} />
                      <Route path="/customers" element={<Customers />} />
                      <Route path="/coupons" element={<Coupons />} />
                      <Route path="/campaigns" element={<Campaigns />} />
                      <Route path="/templates" element={<Templates />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/product-table" element={<ProductTable />} />
                    </Routes>
                  </Dashboard>
                ) : (
                  <Navigate to="/auth" replace />
                )
              }
            />
          </Routes>
        </div>
      </Router>
    </BrandProvider>
  );
}

export default App;
